<template>
  <div id="base-plan" class="mb-10" style="width: 100% !important">
    <div>
      <div class="row">
        <div class="col-md-12" style="background: white">
          <h5 class="mt-4">Mixing report</h5>
          <hr />
          <div class="row mt-4">
            <div class="col-md-2">
              <div class="form-group">
                <label for="">Date</label>
                <date-picker
                  :date-format="'YYYY-MM-DD'"
                  :shortcuts="dateoptions"
                  valueType="format"
                  v-model="date"
                  :class="[errors['date'] ? 'border border-danger' : '']"
                ></date-picker>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div
          class="col-md-12"
          id="resp"
          style="background: white; font-size: 12px"
        >
          <table
            class="table table-striped table-responsive"
            style="width: 68.5%; margin-left: 22%"
          >
            <thead>
              <tr>
                <th class="headcol">S.No.</th>
                <th class="varietycol" style="white-space: nowrap">Variety</th>
                <th style="white-space: nowrap">Flour</th>
                <th style="white-space: nowrap">Corn Flour</th>
                <th style="white-space: nowrap">Veg Fat</th>
                <th style="white-space: nowrap">Sugar</th>
                <th style="white-space: nowrap">Invert Syrup</th>
                <th style="white-space: nowrap">Malt Ext.</th>
                <th style="white-space: nowrap">Salt</th>
                <th style="white-space: nowrap">GMS</th>
                <th style="white-space: nowrap">Lecithin</th>
                <th style="white-space: nowrap">Ammonia</th>
                <th style="white-space: nowrap">Soda</th>
                <th style="white-space: nowrap">S.M.S.</th>
                <th style="white-space: nowrap">Yeast</th>
                <th style="white-space: nowrap">Papain</th>
                <th style="white-space: nowrap">Cocokita Oil</th>
                <th style="white-space: nowrap">Soya</th>
                <th style="white-space: nowrap">Vitamin</th>
                <th style="white-space: nowrap">Cooca Powd.</th>
                <th style="white-space: nowrap">Coconut Powd.</th>
                <th style="white-space: nowrap">Bran</th>
                <th style="white-space: nowrap">Dough Serap</th>
                <th style="white-space: nowrap">Biscuit Pdr.</th>
                <!-- <th style=""></th> -->
                <th
                  class="lastcol"
                  style="width: 9%; background-color: white; text-align: left"
                >
                  Action
                </th>
              </tr>
              <tr
                v-for="(item, index) in details"
                :key="index"
                :class="[item.error == 1 ? 'row_red' : '']"
              >
                <td class="headcol" style="width: 3%; padding: 0.75rem">
                  {{ index + 1 }}
                </td>
                <td class="varietycol">
                  <input
                    :id="'detail_variety_' + index"
                    type="text"
                    v-model="item.variety"
                    class="form-control"
                  />
                </td>
                <td style="width: 10%; margin-left: 5rem !important">
                  <input
                    :id="'detail_flour_' + index"
                    type="text"
                    v-model="item.flour"
                    class="form-control"
                  />
                </td>
                <td style="width: 10%">
                  <input
                    type="text"
                    :id="'detail_corn_flour_' + index"
                    v-model="item.corn_flour"
                    class="form-control"
                  />
                </td>
                <td style="width: 10%">
                  <input
                    type="text"
                    :id="'detail_veg_fat_' + index"
                    v-model="item.veg_fat"
                    class="form-control"
                  />
                </td>
                <td style="width: 10%">
                  <input
                    type="text"
                    :id="'detail_sugar_' + index"
                    v-model="item.sugar"
                    class="form-control"
                  />
                </td>

                <td style="width: 10%">
                  <input
                    type="text"
                    :id="'detail_invert_syrup_' + index"
                    v-model="item.invert_syrup"
                    class="form-control"
                  />
                </td>
                <td style="width: 10%">
                  <input
                    type="text"
                    :id="'detail_malt_ext_' + index"
                    v-model="item.malt_ext"
                    class="form-control"
                  />
                </td>
                <td style="width: 10%">
                  <input
                    :id="'detail_salt_' + index"
                    type="text"
                    v-model="item.salt"
                    class="form-control"
                  />
                </td>
                <td style="width: 10%">
                  <input
                    type="text"
                    :id="'detail_gms_' + index"
                    v-model="item.gms"
                    class="form-control"
                  />
                </td>
                <td style="width: 10%">
                  <input
                    type="text"
                    :id="'detail_lecithin_' + index"
                    v-model="item.lecithin"
                    class="form-control"
                  />
                </td>
                <td style="width: 10%">
                  <input
                    type="text"
                    :id="'detail_ammonia_' + index"
                    v-model="item.ammonia"
                    class="form-control"
                  />
                </td>

                <td style="width: 10%">
                  <input
                    type="text"
                    :id="'detail_soda_' + index"
                    v-model="item.soda"
                    class="form-control"
                  />
                </td>
                <td style="width: 10%">
                  <input
                    type="text"
                    :id="'detail_sms_' + index"
                    v-model="item.sms"
                    class="form-control"
                  />
                </td>
                <td style="width: 10%">
                  <input
                    :id="'detail_yeast_' + index"
                    type="text"
                    v-model="item.yeast"
                    class="form-control"
                  />
                </td>
                <td style="width: 10%">
                  <input
                    type="text"
                    :id="'detail_papain_' + index"
                    v-model="item.papain"
                    class="form-control"
                  />
                </td>
                <td style="width: 10%">
                  <input
                    type="text"
                    :id="'detail_cocokita_oil_' + index"
                    v-model="item.cocokita_oil"
                    class="form-control"
                  />
                </td>
                <td style="width: 10%">
                  <input
                    type="text"
                    :id="'detail_soya_' + index"
                    v-model="item.soya"
                    class="form-control"
                  />
                </td>

                <td style="width: 10%">
                  <input
                    type="text"
                    :id="'detail_vitamin_' + index"
                    v-model="item.vitamin"
                    class="form-control"
                  />
                </td>
                <td style="width: 10%">
                  <input
                    type="text"
                    :id="'detail_cocoa_powder_' + index"
                    v-model="item.cocoa_powder"
                    class="form-control"
                  />
                </td>
                <td style="width: 10%">
                  <input
                    type="text"
                    :id="'detail_coconut_powder_' + index"
                    v-model="item.coconut_powder"
                    class="form-control"
                  />
                </td>
                <td style="width: 10%">
                  <input
                    type="text"
                    :id="'detail_bran_' + index"
                    v-model="item.bran"
                    class="form-control"
                  />
                </td>
                <td style="width: 10%">
                  <input
                    type="text"
                    :id="'detail_dough_serap_' + index"
                    v-model="item.dough_serap"
                    class="form-control"
                  />
                </td>

                <td style="width: 10%">
                  <input
                    type="text"
                    :id="'detail_biscuit_powder_' + index"
                    v-model="item.biscuit_powder"
                    class="form-control"
                  />
                </td>
                <!-- <td style="width: 20%"></td> -->

                <td class="lastcol" style="width: 9%; background-color: white">
                  <button
                    v-if="details.length - 1 != 0"
                    class="btn btn-danger btn-sm mr-1"
                    @click="removeplan(index)"
                  >
                    Delete
                  </button>
                  <button
                    v-if="index == details.length - 1"
                    class="btn btn-success btn-sm"
                    @click="planpush()"
                  >
                    Add
                  </button>
                </td>
              </tr>
            </thead>

            <tbody>
              <div></div>
            </tbody>
          </table>
        </div>
      </div>
      <div class="btn btn-smplan-fixed">
        <button
          ref="kt_submit_base_plan"
          class="btn btn-warning mt-2 mb-2 mr-5"
          @click="goback()"
        >
          Go back
        </button>
        <button
          v-if="!isEdit"
          ref="kt_submit_base_plan"
          class="btn btn-success mt-2 mb-2 mr-3"
          @click="save()"
        >
          Save
        </button>
        <button
          v-if="isEdit"
          ref="kt_submit_base_plan"
          class="btn btn-success mt-2 mb-2 mr-3"
          @click="update()"
        >
          Update
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
// import Swal from "sweetalert2/dist/sweetalert2.js";

import {
  STORE_DAILY_MIXING_REPORT,
  FETCH_SELF_MIXING_REPORT,
  UPDATE_DAILY_MIXING_REPORT,
} from "@/core/services/store/actions.type";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
// import moment from "moment";

import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
export default {
  components: {
    DatePicker,
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  data() {
    return {
      dateoptions: [{ text: "Today", onClick: () => new Date() }],
      isEdit: false,
      plan: {
        variety: "",
        flour: "",
        corn_flour: "",
        veg_fat: "",
        sugar: "",
        invert_syrup: "",
        malt_ext: "",
        salt: "",
        gms: "",
        lecithin: "",
        ammonia: "",
        soda: "",
        sms: "",
        yeast: "",
        papain: "",
        cocokita_oil: "",
        soya: "",
        vitamin: "",
        cocoa_powder: "",
        coconut_powder: "",
        bran: "",
        dough_serap: "",
        biscuit_powder: "",

        error: 0,
      },
      id: 0,

      results: [],
      errors: {},
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard",
        },
        {
          id: 2,
          title: "Create Mixing Report",
          route: "",
        },
      ],
      details: [],
      shift: "",
      date: this.$moment(new Date()).format("YYYY-MM-DD"),
      add_status: 1,
      error_status: 1,
    };
  },
  created() {
    this.isEdit = this.$route.name == "client.dailymixing.edit" ? true : false;
    var id = this.$route.params.id;

    if (this.isEdit) {
      this.breadCrumbs[1].title = "Update Mixing Report";
      this.$store
        .dispatch(FETCH_SELF_MIXING_REPORT, id)
        .then((data) => {
          if (data.data.over_report_detail.length == 0) {
            this.planpush(1);
            this.planpush(2);
            this.planpush(3);
            this.planpush(4);
          } else {
            this.details = data.data.over_report_detail;
          }
          this.date = data.data.date;
        })
        .catch(() => {
          this.$toastr.e("detail not found!");
          this.$router.push({ name: "client.ovenreport" });
        });
    } else {
      this.planpush(1);
      this.planpush(2);
      this.planpush(3);
      this.planpush(4);
    }
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
  },
  methods: {
    save() {
      const det = this.details.filter((data) => {
        return (
          data.variety != "" ||
          data.flour != "" ||
          data.corn_flour != "" ||
          data.veg_fat != "" ||
          data.sugar != "" ||
          data.invert_syrup != "" ||
          data.malt_ext != "" ||
          data.salt != "" ||
          data.gms != "" ||
          data.lecithin != "" ||
          data.ammonia != "" ||
          data.soda != "" ||
          data.sms != "" ||
          data.yeast != "" ||
          data.papain != "" ||
          data.cocokita_oil != "" ||
          data.soya != "" ||
          data.vitamin != "" ||
          data.cocoa_powder != "" ||
          data.coconut_powder != "" ||
          data.bran != "" ||
          data.dough_serap != "" ||
          data.biscuit_powder != ""
        );
      });
      let msg = "Please fill all the value";
      var err = 0;
      this.errors = {};
      if (this.date == "") {
        this.errors["date"] = "This field is required";
        err = 1;
      }
      if (err == 1) {
        this.$toastr.w(msg);
        return 0;
      }
      if (det.length < 1) {
        let msg = "Please add atleast one record";
        this.$toastr.w(msg);
        return 0;
      }
      var formData = {
        mixing_detail: det,

        date: this.date,
      };
      const submitButton = this.$refs["kt_submit_base_plan"];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.$store
        .dispatch(STORE_DAILY_MIXING_REPORT, formData)
        .then((res) => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
          this.$toastr.s(res.msg);
          this.$router.push({
            name: "client.dailymixing",
          });
        })
        .catch(() => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
          this.$toastr.e("Something went wrong !");
        });
    },
    update() {
      const det = this.details.filter((data) => {
        return (
          data.variety != "" ||
          data.flour != "" ||
          data.corn_flour != "" ||
          data.veg_fat != "" ||
          data.sugar != "" ||
          data.invert_syrup != "" ||
          data.malt_ext != "" ||
          data.salt != "" ||
          data.gms != "" ||
          data.lecithin != "" ||
          data.ammonia != "" ||
          data.soda != "" ||
          data.sms != "" ||
          data.yeast != "" ||
          data.papain != "" ||
          data.cocokita_oil != "" ||
          data.soya != "" ||
          data.vitamin != "" ||
          data.cocoa_powder != "" ||
          data.coconut_powder != "" ||
          data.bran != "" ||
          data.dough_serap != "" ||
          data.biscuit_powder != ""
        );
      });
      let msg = "Please fill all the value";
      var err = 0;
      this.errors = {};
      if (this.date == "") {
        this.errors["date"] = "This field is required";
        err = 1;
      }

      if (err == 1) {
        this.$toastr.w(msg);
        return 0;
      }
      if (det.length < 1) {
        let msg = "Please add atleast one record";
        this.$toastr.w(msg);
        return 0;
      }

      var formData = {
        id: this.$route.params.id,
        mixing_detail: det,

        date: this.date,
      };

      const submitButton = this.$refs["kt_submit_base_plan"];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      this.$store
        .dispatch(UPDATE_DAILY_MIXING_REPORT, formData)
        .then((res) => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
          this.$toastr.s(res.msg);
          this.$router.push({
            name: "client.dailymixing",
          });
        })
        .catch(() => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
          this.$toastr.e("Something went wrong !");
        });
    },
    removeplan(index) {
      this.details.splice(index, 1);
    },
    planpush() {
      let plan = {
        variety: "",
        flour: "",
        corn_flour: "",
        veg_fat: "",
        sugar: "",
        invert_syrup: "",
        malt_ext: "",
        salt: "",
        gms: "",
        lecithin: "",
        ammonia: "",
        soda: "",
        sms: "",
        yeast: "",
        papain: "",
        cocokita_oil: "",
        soya: "",
        vitamin: "",
        cocoa_powder: "",
        coconut_powder: "",
        bran: "",
        dough_serap: "",
        biscuit_powder: "",
      };
      this.details.push(plan);
      this.add_status = 1;
    },
    reset() {
      this.plan = {
        variety: "",
        flour: "",
        corn_flour: "",
        veg_fat: "",
        sugar: "",
        invert_syrup: "",
        malt_ext: "",
        salt: "",
        gms: "",
        lecithin: "",
        ammonia: "",
        soda: "",
        sms: "",
        yeast: "",
        papain: "",
        cocokita_oil: "",
        soya: "",
        vitamin: "",
        cocoa_powder: "",
        coconut_powder: "",
        bran: "",
        dough_serap: "",
        biscuit_powder: "",
      };
    },
    goback() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
.row_red {
  background-color: #f89494 !important;
}
.btn-sm,
.btn-group-sm > .btn {
  padding: 0.5rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.35;
  border-radius: 0.42rem;
}
.base-plan {
  .btn-plan-fixed {
    position: fixed;
    right: 3%;
    top: 89%;
    width: 75%;
    background-color: black;
  }
}
.table thead th,
.table thead td {
  font-size: 12px !important;
}
.btn-smplan-fixed {
  position: fixed;
  top: 93%;
  background-color: #ffff;
  width: calc(71vw - 0%);

  button {
    float: right;
  }
}
.form-control {
  font-size: 10px !important;
  padding: 0.25rem 0.25rem;
  height: calc(1.5em + 1.3rem);
}
table {
  td {
    padding: 0.25rem;
    vertical-align: top;
    border-top: 1px solid #ebedf3;
  }
  .baseplan-header {
    background: #b4cfe2;
  }
}
</style>
<style>
.swal2-popup .swal2-icon {
  margin-left: 39% !important;
}
.icon-green {
  color: green;
}
.icon-red {
  color: red;
}
</style>

<style scoped>
input[type="number"] {
  -moz-appearance: textfield !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.action {
  width: 20%;
}
.datetime {
  width: 10%;
}
.ino {
  width: 4%;
}
.form-inline-flex {
  display: inline-flex;
}
.label1 {
  margin-right: 2%;
  margin-top: 2%;
}
.schedule-input {
  width: 27%;
  margin-right: 2%;
}
.label2 {
  margin-top: 2%;
}
td,
th {
  margin: 0;
  white-space: nowrap;
  border-top-width: 0px;
}
.resp {
  overflow-x: scroll;
  overflow-y: visible;
  padding: 0;
}
.headcol {
  position: absolute;
  width: 5em;
  left: 0;
  top: auto;
  border-top-width: 2px;
  margin-top: -1px;
  background-color: white;
}
.varietycol {
  position: absolute;
  width: 18%;
  left: 3rem;
  top: auto;
  border-top-width: 2px;
  margin-top: -1px;
  background-color: white;
  border-right: 1px solid black;
}
.mx-input-wrapper {
  border: 1px solid #e4e6ef;
  border-radius: 0.45rem;
}
.mx-datepicker .mx-input {
  height: 2.8rem;
}
.lastcol {
  position: absolute;
  width: 5em;
  right: 0;
  top: auto;
  border-top-width: 2px;
  margin-top: -1px;
  background-color: white;
  border-left: 1px solid black;
}

.long {
  background: #8cdba3;
  letter-spacing: 1em;
}
</style>
